@import "../../_globalColor";

.experience-card, .experience-card-dark {
  position: relative;
  background-color: $lightBackground2; // Default to light background for light mode
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
  overflow: hidden;
  transition: all 0.5s ease;
}

.experience-card-dark {
  background-color: $darkBackground; // Specific background for dark mode
}

.experience-card:hover, .experience-card-dark:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}

// TODO: transition the blur for cards with Vimeo integration
.experience-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150%;
  background: $experienceCardBannerLG;
  border-radius: 10px 10px 0px 0px;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
  height: 11rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  transition: height 0.5s ease;
  z-index: 10; // Higher value than the text details
}

.experience-blurred_div {
  position: absolute;
  background: $experienceCardBlurredDivLG;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  height: 11rem;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.experience-div-company {
  position: absolute;
  background: transparent;
  height: 9rem;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;
}
.card-expanded .experience-div-company {
  transform: translateY(-100%); // Move company info up to the banner
}

.experience-text-company {
  text-align: center;
  padding: 1.5rem;
  margin: 0;
  color: $lightBackground1;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  opacity: 1;
}
.card-expanded .experience-text-company {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.card-collapsed .experience-text-company {
  opacity: 1;
}

.experience-roundedimg {
  position: absolute;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 7rem;
  margin-left: auto;
  margin-right: auto;
  width: 8rem;
  height: 8rem;
  box-shadow: 0 0.5rem 1rem $lightBoxShadowDarker;
  border-radius: 50%;
  transition: top 0.5s ease;
}
.card-expanded .experience-roundedimg {
  top: 1.5rem; // (experience-banner-height / 2) - (experience-roundedimg-height / 2)
}
.card-collapsed .experience-roundedimg {
  top: 7rem; // Reset to original position
}

.experience-text-role {
  text-align: center;
  color: $textColor;
  font-weight: 700;
  font-size: 25px;
  margin: 0px;
  padding-top: 1.5rem;
  line-height: normal;
}
.dark-mode-text {
  color: $textColorDark !important;
}
.experience-text-date {
  text-align: center;
  color: $textColor;
  font-size: 20px;
  margin: 0px;
  padding-top: 1rem;
  font-weight: 600;
}

.experience-text-desc {
  text-align: center;
}

.experience-text-details {
  position: relative;
  z-index: 1; // Lower value than the banner
  padding: 1.5rem;
  margin-top: 2rem;
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.card-expanded .experience-text-details {
  transform: translateY(-100%);
  opacity: 0;
}
.card-collapsed .experience-text-details {
  transform: translateY(0%);
  opacity: 1;
}

.vimeo-player {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: translateY(100%); // Start hidden below
  opacity: 0;
  pointer-events: none; // Disable interaction when hidden
}
.vimeo-player.show {
  transform: translateY(0); // Move to visible position
  opacity: 1;
  pointer-events: auto; // Enable interaction when visible
}
.vimeo-player.hide {
  transform: translateY(100%);
  opacity: 0;
}

/* Media Query */

@media (max-width: 1380px) {
  .experience-text-role {
    padding-top: 0.5rem;
    font-size: 22px;
  }
  .experience-text-company {
    font-size: 22px;
  }
  .experience-text-date {
    font-size: 18px;
  }
  .experience-roundedimg {
    width: 6.5rem;
    height: 6.5rem;
  }
  .card-expanded .experience-roundedimg {
    top: 2.25rem; // (experience-banner-height / 2) - (experience-roundedimg-height / 2)
  }
}

@media (max-width: 768px) {
  .experience-text-role {
    font-size: 22px;
  }
  .experience-text-company {
    font-size: 22px;
  }
  .experience-text-date {
    font-size: 18px;
  }
  .experience-text-desc {
    font-size: 16px;
  }
}
